import React from "react"
import LoginOrProfile from "./LoginOrProfile"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from './BoardLogo'

import { Disclosure } from "@headlessui/react"
import { X as XIcon } from "@styled-icons/heroicons-outline/X"
import { Menu as MenuIcon } from "@styled-icons/heroicons-outline/Menu"

const myRoomDesignerDomains = ['myroomdesigner.ai', 'www.myroomdesigner.ai'];

function checkIsMyRoomDesignerAI(path) {
  if (typeof window !== 'undefined') {
    return myRoomDesignerDomains.includes(window.location.hostname) || path.startsWith('/interior-design-ai');
  }
  return path.startsWith('/interior-design-ai');
}

const NavLink = React.forwardRef((props,ref) => (
  <a
    {...props}
    ref={ref}
    className={`px-4 py-2 rounded-full hover:bg-pink-50 cursor-pointer  ${
      props.current ? "text-pink-800 bg-pink-50" : ""
    }
    ${props.highlight ? "text-white bg-gradient-to-r from-pink-900 to-pink-700 hover:ring-2 hover:ring-pink-300" : "hover:text-pink-800"}
    ${!props.highlight && !props.current ? "text-gray-600" : ""}}`}
  >
    {props.children}
  </a>
))

const Button = React.forwardRef((props,ref) => (
  <button
    {...props}
    ref={ref}
    className="inline-flex items-center justify-center p-2 rounded-full text-pink-800 hover:bg-pink-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-200"
  >
    {props.children}
  </button>
))

export default function NavBar() {
  const router = useRouter()
  const { t } = useTranslation()

  const isMyRoomDesignerAI = checkIsMyRoomDesignerAI(router.asPath)

  const navigation = [
    {
      name: t("hero.button"),
      href: "/new-board",
      highlight: true,
      mobileOnly: true,
      showOnMyRoomDesignerAI: false,
    },
    {
      name: "Ideas",
      href:
        "/ai",
    },
    {
      name: t("nav.ai"),
      href: "https://moodboardai.com/ai-generator",
      onClick: e => {
        e.preventDefault()
        router.push("/ai-generator")
      },
      showOnMyRoomDesignerAI: true,
    },
    {
      name: "Room Design AI",
      // href: "https://myroomdesigner.ai",
      onClick: e => {
        e.preventDefault()
        router.push("/interior-design-ai")
      },
      showOnMyRoomDesignerAI: true,
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-20 bg-white/80 backdrop-blur-md"
    >
      {({ open }) => (
        <div>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button as={Button}>
                  <div className="">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </div>
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <a href="/" className="flex-grow-0 sm:flex sm:items-center" title="Mood Boards">
                    <Logo className="h-9 w-9 sm:h-8" />
                  </a>
                <div className="hidden sm:flex sm:items-center sm:ml-6">
                  <div className="flex gap-1 text-md">
                    {navigation
                      .filter(item => !item.mobileOnly)
                      .filter(item => !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI)
                      .map(({ name, href, current, highlight, onClick }) => (
                          <NavLink
                            key={name}
                            href={href}
                            current={router.asPath === href ? "true" : undefined}
                            aria-current={
                              router.asPath === href ? "page" : undefined
                            }
                            highlight={highlight ? "true" : undefined}
                            onClick={onClick}
                          >
                            {name}
                          </NavLink>
                      ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {!isMyRoomDesignerAI && <div className="hidden sm:flex gap-4">
                    <NavLink href="/new-board" highlight="true">{t("hero.button")}</NavLink>
                </div>}
                <LoginOrProfile />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-2">
              {navigation
                .filter(item => !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI)
                .map(({ name, href, highlight, onClick }) => (
                  <a
                    href={href} 
                    key={name}
                    className={`block  hover:bg-pink-100 hover:text-pink-800 px-4 py-2 rounded-full disabled:opacity-50 ${
                      highlight
                        ? "text-pink-100 bg-pink-900"
                        : "text-pink-800 bg-pink-50"
                    }`}
                    aria-current={router.asPath === href ? "page" : undefined}
                    onClick={onClick}
                  >
                    {name}
                  </a>
                ))}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
